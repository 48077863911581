import React, { useContext } from "react"
import getCascadingColor from "../../functions/getCascadingColor"
import useIsInScreen from "../../hooks/useIsInScreen"
import { fadeSlideIn } from "../../styles/animations"
import BloqmatterLink from "../atoms/BloqmatterLink"
import Button from "../atoms/Button"
import H2 from "../atoms/H2"
import TextBasic from "../atoms/TextBasic"
import { DataContext } from "../Layout"
import { DEFAULT_BLOCKOPTIONS_DEFAULTS } from "@rubendenbrok/bloqmatter-cms"
import ResponsiveImg from "../molecules/ResponsiveImg"

type props = any

/*
Just a basic text box + title and CTA

DATA {
title : string
text : string (rich text html)
textAlign: 'start'|'left'|'center'
CTA Input group 
    default: 
    {buttonlink: Link, 
    buttonColor1: color, 
    buttonColor2: color}
*/

export default function BasicImageBlock_Display({ blockContent }: props) {
  const { cssVariables, thisPage, siteOverviewData } = useContext(DataContext)
  const [animRef, isVisible] = useIsInScreen("100px")

  return (
    <>
      <div className="md:px-4">
        <ResponsiveImg image={blockContent.image}></ResponsiveImg>
      </div>
    </>
  )
}

export const basicImageBlock_inputs = {
  key: "basicImageBlock",
  name: "Basic Image Block",
  inputs: [
    {
      inputType: "image",
      label: "Image",
      keyName: "image",
    },
  ],
  defaults: {
    ...DEFAULT_BLOCKOPTIONS_DEFAULTS,
  },
}
