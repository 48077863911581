import React, { useContext, useState, useRef, useEffect } from "react"
import { Link, Page } from "@rubendenbrok/bloqmatter-cms"
import useSafeWindowEventlistener from "../../hooks/useSafeWindowEventlistener"
import BloqmatterLink from "../atoms/BloqmatterLink"
import { DataContext } from "../Layout"
import MenuButton from "../atoms/MenuButton"

const navbarHeight = "50px"

export function handleScrollTo(id: string) {
  if (typeof window !== "undefined") {
    const elem = document.getElementById(id)
    console.log(elem)
    if (elem) {
      elem.scrollIntoView({
        behavior: "smooth",
        block: "center",
      })
    }
  }
}

export default function DesktopHeader() {
  const {
    thisPage,
    allPages,
    cssVariables,
    footerData,
    headerData,
    processedImageData,
    videoData,
  } = useContext(DataContext)
  const [showMenu, setShowMenu] = useState(false)
  const showRef = useRef(false)

  function handleScroll() {
    if (typeof window !== "undefined") {
      if (!showRef.current && window.scrollY > window.innerHeight - 300) {
        showRef.current = true
        setShowMenu(true)
      }
      if (showRef.current && window.scrollY < window.innerHeight - 400) {
        showRef.current = false
        setShowMenu(false)
      }
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <>
      <header
        className={`w-full sm:inline-block hidden fixed overflow-y-visible`}
        style={{
          backgroundColor: cssVariables.colors[headerData.bgColor] + "ee",
          color: cssVariables.colors[headerData.textColor],
          zIndex: 999,
          top: "0px",
          transition: "transform 0.3s",
          transform: !showMenu
            ? `translateY(-${navbarHeight})`
            : "translateY(0)",
        }}
      >
        <nav
          className={`w-full flex items-center justify-between max-w-[820px] px-8 mx-auto relative `}
          style={{ height: navbarHeight }}
        >
          <MenuButton small onClick={() => handleScrollTo("About")}>
            About
          </MenuButton>
          <MenuButton small onClick={() => handleScrollTo("Music")}>
            Music
          </MenuButton>
          <MenuButton small onClick={() => handleScrollTo("Tour")}>
            Tour
          </MenuButton>
          <MenuButton small onClick={() => handleScrollTo("Contact")}>
            Contact
          </MenuButton>
        </nav>
      </header>
    </>
  )
}

type menuItemProps = {
  isDropdown: boolean
  childMenuItems: any[]
  link: Link
  allPages: Page[]
  accentColor: number
  bgColor: number
  cssVariables: { [x: string]: string }
}

function MenuItem({
  isDropdown,
  childMenuItems,
  link,
  allPages,
  accentColor,
  bgColor,
  cssVariables,
}: menuItemProps) {
  const [visible, setVisible] = useState(false)
  return (
    <div
      className="relative z-50 h-full flex justify-center items-center overflow-visible"
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => {
        if (!Array.isArray(childMenuItems) || !isDropdown) {
          setVisible(false)
        }
      }}
    >
      {/* PARENT LINK */}
      <BloqmatterLink link={link}>
        <MenuParentItem
          link={link}
          cssVariables={cssVariables}
          visible={visible}
          setVisible={setVisible}
          childMenuItems={childMenuItems}
          isDropdown={isDropdown}
          accentColor={accentColor}
        />
      </BloqmatterLink>

      {/* DROPDOWN */}
      {visible && isDropdown && Array.isArray(childMenuItems) && (
        <div
          onMouseLeave={() => setVisible(false)}
          className="absolute top-0 left-4"
        >
          <BloqmatterLink link={link}>
            <div style={{ height: navbarHeight }} className="w-full"></div>
          </BloqmatterLink>
          <div
            style={{
              // backdropFilter: "blur(5px) saturate(0%) invert(100%)",
              backgroundColor: cssVariables.colors[bgColor] + "ee",
            }}
          >
            {childMenuItems.map(item => {
              if (
                item.visibility === "always" ||
                item.visibility === "desktop"
              ) {
                return (
                  <BloqmatterLink key={link.text} link={item.link}>
                    <MenuChildItem
                      link={item.link}
                      cssVariables={cssVariables}
                      visible={visible}
                      setVisible={setVisible}
                      childMenuItems={childMenuItems}
                      isDropdown={isDropdown}
                      accentColor={accentColor}
                    />
                  </BloqmatterLink>
                )
              }
            })}
          </div>
        </div>
      )}
    </div>
  )
}

type MenuLinkProps = {
  link: Link
  cssVariables: { [x: string]: string }
  visible: boolean
  setVisible: Function
  isDropdown: boolean
  childMenuItems: any[]
  accentColor: number
}

function MenuParentItem({
  link,
  cssVariables,
  visible,
  setVisible,
  accentColor,
}: MenuLinkProps) {
  return (
    <div
      className="px-10 py-4 h-full font-titleAccent text-xl relative"
      style={{ textShadow: "3px 3px 2px black" }}
    >
      {link.text}

      {/* DECORATION */}
      <div
        className="absolute left-1/2 transform -translate-x-1/2 bottom-2 h-0.5 transition-all"
        style={{
          width: visible ? "100px" : "30px",
          opacity: visible ? 1 : 0,
          backgroundColor: cssVariables.colors[accentColor],
        }}
      ></div>
    </div>
  )
}

function MenuChildItem({
  link,
  cssVariables,
  visible,
  setVisible,
  accentColor,
}: MenuLinkProps) {
  const [isVisible, setIsVisible] = useState(false)
  return (
    <>
      <div
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        className="px-4 py-4 font-titleAccent block relative "
        style={{ width: "max-content", textShadow: "3px 3px 2px black" }}
      >
        {link.text}
        {/* DECORATION */}
        <div
          className="absolute left-2 bottom-2 h-0.5 transition-all "
          style={{
            width: isVisible ? "120px" : "30px",
            opacity: isVisible ? 1 : 0,
            backgroundColor: cssVariables.colors[accentColor],
          }}
        ></div>
      </div>
    </>
  )
}
