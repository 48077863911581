import React, { ReactNode } from "react"

type elementProps = {
  className?: string
  color?: string
  children?: ReactNode
  setInnerHtml?: string
}

export default function H3({
  className,
  color,
  children,
  setInnerHtml,
}: elementProps) {
  return (
    <h3
      style={{ color: color ? color : "inherit" }}
      className={`font-titleAccent sm:text-3xl text-2xl font-normal m-0 select-none ${className}`}
      //dangerouslySetInnerHTML={setInnerHtml ? { __html: setInnerHtml } : null}
    >
      {children}
    </h3>
  )
}
