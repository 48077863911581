import React, { useContext } from "react"
import { Page } from "@rubendenbrok/bloqmatter-cms"
import { DataContext } from "../Layout"
import ResponsiveBgImg from "./ResponsiveBgImg"
import ResponsiveImg from "./ResponsiveImg"
import useStore from "../../store/store"

export default function Footer() {
  const { thisPage, cssVariables, footerData, headerData } =
    useContext(DataContext)
  const setIsShowingCookiePopup = useStore(
    state => state.setIsShowingCookiePopup
  )
  return (
    <footer className="py-32  relative font-titleAccent tracking-widest">
      <div className="absolute w-full h-full left-0 top-0 z-0 opacity-60">
        <ResponsiveBgImg image={footerData.bgImage} />
      </div>
      <div className="z-10 relative max-w-[820px] w-full px-4 å mx-auto">
        <div className="md:text-lg text-md">
          Artwork by{" "}
          <a
            className="relative cursor-pointer hover:underline"
            target="_blank"
            href="https://www.marjanderidder.com"
          >
            Marjan de Ridder
          </a>
        </div>
        <div className="md:text-lg text-md">
          Photography by{" "}
          <a
            className="relative cursor-pointer hover:underline"
            target="_blank"
            href="https://www.mondayjr.com"
          >
            Monday Jr.
          </a>
        </div>
        <div className="mt-20 md:text-md text-sm">Copyright © 2023</div>
        <div
          onClick={() => setIsShowingCookiePopup(true)}
          className="cursor-pointer hover:underline  md:text-md text-sm"
        >
          Cookie settings
        </div>
      </div>
    </footer>
  )
}
