import React, { useContext } from "react"
import { getItemFromId } from "@rubendenbrok/bloqmatter-cms"
import getCascadingColor from "../../functions/getCascadingColor"
import { getAbsolutePositionStyle } from "../../styles/util"
import H1 from "../atoms/H1"
import H2 from "../atoms/H2"
import { DEFAULT_BLOCKOPTIONS_DEFAULTS } from "@rubendenbrok/bloqmatter-cms"

import { DataContext } from "../Layout"
import BgVideo from "../molecules/BgVideo"
import ResponsiveBgImg from "../molecules/ResponsiveBgImg"
import H3 from "../atoms/H3"
import HoverLines from "../atoms/HoverLines"
import TextBasic from "../atoms/TextBasic"
import MenuButton from "../atoms/MenuButton"
import { handleScrollTo } from "../header/DesktopHeader"

/*
BESCHRIJVING:
Een Hero video die altijd de breedte van het scherm aanhoudt, met een eventuele tekst erover.

DATA:
{
  title: string
  subtitle: string
  text: html string
  textHorizontalPos: 'start' | 'center' | 'end'
  textVerticalPos: 'start' | 'center' | 'end'
  video: string (video ID)


}
*/

type props = any

export default function HeroMenuBlock_Display({ blockContent }: props) {
  const { cssVariables, thisPage, siteOverviewData, processedImageData } =
    useContext(DataContext)
  const imgObj = getItemFromId(blockContent.image, processedImageData)
  // const ratio = videoObj?.metadata.height / videoObj?.metadata.width

  const textboxPositionStyle = getAbsolutePositionStyle(
    blockContent.textHorizontalPos,
    blockContent.textVerticalPos,
    "80px"
  )

  return (
    <>
      <div className="w-full relative h-screen">
        <div className="fadeInSlow text-center absolute w-full left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center">
          {blockContent.title && (
            <H1
              className={` text-center`}
              style={{
                color: thisPage
                  ? getCascadingColor(
                      cssVariables,
                      siteOverviewData,
                      thisPage,
                      blockContent
                    ).title
                  : "black",
                animationDelay: "0.5s",
              }}
            >
              {blockContent.title}
            </H1>
          )}
          <div className="flex justify-between lg:w-[735px] sm:w-[480px] sm:flex-row flex-col  sm:h-auto mt-4 sm:mt-0">
            <MenuButton
              onClick={() => {
                handleScrollTo("About")
              }}
            >
              About
            </MenuButton>
            <MenuButton
              onClick={() => {
                handleScrollTo("Music")
              }}
            >
              Music
            </MenuButton>
            <MenuButton
              onClick={() => {
                handleScrollTo("Tour")
              }}
            >
              Tour
            </MenuButton>
            <MenuButton
              onClick={() => {
                handleScrollTo("Contact")
              }}
            >
              Contact
            </MenuButton>
          </div>
          <a
            href="https://manifoldmusic.netlify.app/"
            className="italic font-titleAccent md:text-xl cursor-pointer hover:underline mt-8 max-w-[70vw] px-4"
          >
            Click here to explore Manifold, an interactive online piano
            composition
          </a>
        </div>
      </div>
    </>
  )
}

export const HeroMenuBlock_Inputs = {
  key: "heroMenuBlock",
  name: "Hero Menu Block",
  tags: ["Video", "Media", "Hero", "Title"],
  description: "A hero video, with an optional title overlayed on top of it.",
  inputs: [
    {
      inputType: "expandableInputs",
      label: "Text",
      childInputs: [
        {
          inputType: "text",
          label: "Title",
          keyName: "title",
          defaultVal: "",
        },
      ],
    },
  ],
  defaults: {
    ...DEFAULT_BLOCKOPTIONS_DEFAULTS,
  },
}
