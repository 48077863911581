/*
BESCHRIJVING:
Een block dat een video of afbeelding laat zien naast een tekst met titel en subtitel. 
De gebruiker kan kiezen of de media op mobile ook te zien moet zijn, als dat zo is komt die boven de tekst te staan.

De media bewaart altijd zijn oorspronkelijke aspect ratio, wordt niet gecropt, en wordt nooit groter dan zijn oorspronkelijke formaat.

DATA:
{
  title: string
  subtitle: string
  text: html string
  textAlign: 'start' | 'center' | 'end'
  mediaFormat: 'video' | 'image'
  video: string (video ID)
  image: string (image ID)
  switchOrder: boolean (Media verschijnt rechts ipv links van tekst if true)
  showMediaOnMobile: boolean
  buttons: CTA Input group - default: {buttonlink: Link, buttonColor1: color, buttonColor2: color}

}
*/

import React, {
  ChangeEvent,
  FormEvent,
  useContext,
  useRef,
  useState,
} from "react"
import { getItemFromId } from "@rubendenbrok/bloqmatter-cms"
import getCascadingColor from "../../functions/getCascadingColor"
import H3 from "../atoms/H3"
import H2 from "../atoms/H2"
import TextBasic from "../atoms/TextBasic"

import { DataContext } from "../Layout"
import BgVideo from "../molecules/BgVideo"
import Button from "../atoms/Button"
import ResponsiveImg from "../molecules/ResponsiveImg"
import useIsInScreen from "../../hooks/useIsInScreen"
import { fadeSlideIn } from "../../styles/animations"
import { DEFAULT_BLOCKOPTIONS_DEFAULTS } from "@rubendenbrok/bloqmatter-cms"
import HoverLines from "../atoms/HoverLines"

type props = any

export default function MediaMailingList_Display({ blockContent }: props) {
  const {
    cssVariables,
    thisPage,
    siteOverviewData,
    videoData,
    processedImageData,
  } = useContext(DataContext)
  const videoObj = getItemFromId(blockContent.video, videoData)
  const [inputVal, setInputVal] = useState("")
  const [subscribeMsg, setSubscribeMsg] = useState("")
  const emailRef = useRef("")

  const imgObj = getItemFromId(blockContent.image, processedImageData)

  const [animRef, isVisible] = useIsInScreen("100px")
  const scrollAnimation = isVisible ? fadeSlideIn.show : fadeSlideIn.hide

  const textAlignClass =
    blockContent.textAlign === "start"
      ? "sm:text-left text-center"
      : blockContent.textAlign === "center"
      ? "text-center"
      : "sm:text-right text-center"

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    emailRef.current = e.target.value
    setInputVal(e.target.value)
  }

  async function onNewsletterSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    const url = `/.netlify/functions/newsletterSubscribe?email=${emailRef.current}`
    try {
      setSubscribeMsg("Processing...")
      const response = await fetch(url)
      if (response.status === 200) {
        setSubscribeMsg("Thank you, you'll be the first to hear new music!")
        setInputVal("")
        emailRef.current = ""
      } else {
        setSubscribeMsg("Something went wrong, please try again later")
      }
    } catch (e) {
      console.warn(e)
      setSubscribeMsg("Something went wrong, please try again later")
    }
  }

  return (
    <div
      className={`w-full relative flex flex-col justify-center items-center ${
        blockContent.switchOrder ? "lg:flex-row-reverse" : "lg:flex-row"
      }`}
    >
      {/* <div
        className={`relative flex-shrink-0 w-1/2 ${
          blockContent.showMediaOnMobile ? "block" : "hidden lg:block"
        } ${
          blockContent.mediaFormat === "image"
            ? blockContent.switchOrder
              ? "md:pr-20 sm:pl-0 sm:pr-10 px-6"
              : "md:pl-20 sm:pr-0 sm:pl-10 px-6"
            : ""
        }`}
        style={{}}
      >
        {blockContent.mediaFormat === "image" ? (
          <ResponsiveImg image={blockContent.image} />
        ) : (
          <BgVideo videoId={blockContent.video} />
        )}
      </div> */}

      <div
        ref={animRef}
        className={`block lg:w-[60%] w-full ${textAlignClass} flex flex-col items-center md:px-20 px-6 ${
          blockContent.showMediaOnMobile ? "lg:mt-0 mt-6" : ""
        }`}
        style={{
          ...fadeSlideIn.transition,
          ...scrollAnimation,
        }}
      >
        {blockContent.title && (
          <H2
            color={
              thisPage
                ? getCascadingColor(
                    cssVariables,
                    siteOverviewData,
                    thisPage,
                    blockContent
                  ).title
                : "black"
            }
            className={`${textAlignClass} overflow-y-visible`}
          >
            {blockContent.title}
          </H2>
        )}
        {blockContent.subtitle && (
          <H3
            color={
              thisPage
                ? getCascadingColor(
                    cssVariables,
                    siteOverviewData,
                    thisPage,
                    blockContent
                  ).title
                : "black"
            }
            className={`${textAlignClass}  overflow-y-visible`}
          >
            {blockContent.subtitle}
          </H3>
        )}
        {blockContent.text && (
          <TextBasic
            color={
              thisPage
                ? getCascadingColor(
                    cssVariables,
                    siteOverviewData,
                    thisPage,
                    blockContent
                  ).text
                : "black"
            }
            className={`mt-10 ${textAlignClass}`}
            innerHtml={blockContent.text}
          ></TextBasic>
        )}
        <form
          className="flex flex-col items-center"
          onSubmit={onNewsletterSubmit}
        >
          <input
            onChange={handleChange}
            value={inputVal}
            type="text"
            name="test"
            placeholder="Your email adress"
            className="block bg-transparent border-b border-black focus:outline-none py-2 px-1  font-basic text-xl scale-0"
          ></input>
          {!subscribeMsg ? (
            <>
              <input
                onChange={handleChange}
                value={inputVal}
                type="email"
                name="email"
                placeholder="Your email adress"
                className="block bg-transparent border-b border-white border-opacity-60 focus:outline-none py-2 px-1  font-basic text-2xl lg:text-3xl  mb-12 text-center"
              ></input>
              <button
                role="submit"
                className="cursor-pointer font-basic text-2xl lg:text-3xl border-b border-opacity-0 hover:border-opacity-100 transition-all duration-300 border-white"
              >
                - Submit -
              </button>
            </>
          ) : (
            <H3>{subscribeMsg}</H3>
          )}
        </form>
        {blockContent.buttons &&
          blockContent.buttons.map((button: any, index: number) => {
            return (
              <Button
                key={index}
                link={button.buttonLink}
                color1={cssVariables.colors[button.buttonColor1]}
                color2={cssVariables.colors[button.buttonColor2]}
                flip={blockContent.textAlign === "end"}
              ></Button>
            )
          })}
      </div>
    </div>
  )
}

export const MediaMailingListBlock_Inputs = {
  key: "mediaMailingListBlock",
  name: "Media / Mailing list subscribe Block",
  description:
    "A block with an image or video, next to some text and an option to subscribe for a mailing list.",
  tags: ["Media", "Image", "Video", "Text", "CTA"],
  inputs: [
    {
      inputType: "expandableInputs",
      label: "Text",
      childInputs: [
        {
          inputType: "text",
          label: "Title",
          keyName: "title",
          defaultVal: "",
        },
        {
          inputType: "text",
          label: "Subtitle",
          keyName: "subtitle",
          defaultVal: "",
        },
        {
          inputType: "richText",
          label: "Text",
          keyName: "text",
          defaultVal: "",
        },
        {
          inputType: "text",
          label: "Mailing list prompt",
          keyName: "mailText",
          defaultVal: "",
        },
        {
          inputType: "radio",
          labelArr: ["Left", "Center", "Right"],
          valueArr: ["start", "center", "end"],
          label: "Text align",
          keyName: "textAlign",
          defaultVal: "center",
        },
      ],
    },
    {
      inputType: "expandableInputs",
      label: "Media",
      childInputs: [
        {
          inputType: "conditionalRadio",
          label: "Media format",
          keyName: "mediaFormat",
          labelArr: ["Video", "Image"],
          valueArr: ["video", "image"],
          childInputsArr: [
            [
              {
                inputType: "video",
                label: "Video",
                keyName: "video",
                defaultVal: "",
              },
            ],
            [
              {
                inputType: "image",
                label: "Image",
                keyName: "image",
                defaultVal: "",
              },
            ],
          ],
        },
      ],
    },
    {
      inputType: "checkbox",
      label: "Video right of text",
      keyName: "switchOrder",
    },
    {
      inputType: "checkbox",
      label: "Show video on mobile",
      keyName: "showMediaOnMobile",
    },
    { type: "group", inputGroupName: "ctaInputGroup" },
  ],
  defaults: {
    ...DEFAULT_BLOCKOPTIONS_DEFAULTS,
  },
}
