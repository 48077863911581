import React, { useContext } from "react"
import { getItemFromId } from "@rubendenbrok/bloqmatter-cms"
import getCascadingColor from "../../functions/getCascadingColor"
import { getAbsolutePositionStyle } from "../../styles/util"
import H1 from "../atoms/H1"
import H2 from "../atoms/H2"
import { DEFAULT_BLOCKOPTIONS_DEFAULTS } from "@rubendenbrok/bloqmatter-cms"

import { DataContext } from "../Layout"
import BgVideo from "../molecules/BgVideo"
import ResponsiveBgImg from "../molecules/ResponsiveBgImg"

/*
BESCHRIJVING:
Een Hero video die altijd de breedte van het scherm aanhoudt, met een eventuele tekst erover.

DATA:
{
  title: string
  subtitle: string
  text: html string
  textHorizontalPos: 'start' | 'center' | 'end'
  textVerticalPos: 'start' | 'center' | 'end'
  video: string (video ID)


}
*/

type props = any

export default function HeroImageBlock_Display({ blockContent }: props) {
  const { cssVariables, thisPage, siteOverviewData, processedImageData } =
    useContext(DataContext)
  const imgObj = getItemFromId(blockContent.image, processedImageData)
  // const ratio = videoObj?.metadata.height / videoObj?.metadata.width

  const textboxPositionStyle = getAbsolutePositionStyle(
    blockContent.textHorizontalPos,
    blockContent.textVerticalPos,
    "80px"
  )

  const textAlignClass =
    blockContent.textHorizontalPos === "start"
      ? "text-left"
      : blockContent.textHorizontalPos === "center"
      ? "text-center"
      : "text-right"

  return (
    <div
      className="w-full relative"
      // style={{ paddingBottom: ratio * 100 + "%" }}
    >
      <div className="relative w-full   mx-auto overflow-hidden aspect-square">
        <ResponsiveBgImg image={blockContent.image} />
      </div>

      <div
        className={`absolute inline-block ${textAlignClass}   pb-48`}
        style={{ ...textboxPositionStyle }}
      >
        {blockContent.title && (
          <H1
            className={`min-h-[100px] xl:text-8,5xl lg:text-8xl md:text-7xl sm:text-5xl text-3xl font-hero ${textAlignClass}`}
            style={{
              color: thisPage
                ? getCascadingColor(
                    cssVariables,
                    siteOverviewData,
                    thisPage,
                    blockContent
                  ).title
                : "black",
            }}
          >
            {blockContent.title}
          </H1>
        )}
        {blockContent.subtitle && (
          <H2
            color={
              thisPage
                ? getCascadingColor(
                    cssVariables,
                    siteOverviewData,
                    thisPage,
                    blockContent
                  ).title
                : "black"
            }
            className={`text-${blockContent.textHorizontalPos} overflow-y-visible font-titleAccent mt-2`}
          >
            {blockContent.subtitle}
          </H2>
        )}
      </div>
    </div>
  )
}

export const HeroImageBlock_Inputs = {
  key: "heroImageBlock",
  name: "Hero Image Block",
  tags: ["Video", "Media", "Hero", "Title"],
  description: "A hero video, with an optional title overlayed on top of it.",
  inputs: [
    {
      inputType: "expandableInputs",
      label: "Text",
      childInputs: [
        {
          inputType: "text",
          label: "Title",
          keyName: "title",
          defaultVal: "",
        },
        {
          inputType: "text",
          label: "Subtitle",
          keyName: "subtitle",
          defaultVal: "",
        },
        {
          inputType: "radio",
          labelArr: ["Left", "Center", "Right"],
          valueArr: ["start", "center", "end"],
          label: "Title Horizontal Position",
          keyName: "textHorizontalPos",
          defaultVal: "left",
        },
        {
          inputType: "radio",
          labelArr: ["Top", "Center", "Bottom"],
          valueArr: ["start", "center", "end"],
          label: "Title Vertical Position",
          keyName: "textVerticalPos",
          defaultVal: "center",
        },
      ],
    },
    {
      inputType: "expandableInputs",
      label: "Image",
      childInputs: [
        {
          inputType: "image",
          label: "Image",
          keyName: "image",
          defaultVal: "",
        },
      ],
    },
  ],
  defaults: {
    ...DEFAULT_BLOCKOPTIONS_DEFAULTS,
  },
}
