import React, { ReactNode, useState } from "react"

import { Link } from "@rubendenbrok/bloqmatter-cms"
import BloqmatterLink from "./BloqmatterLink"
import TextBasic from "./TextBasic"

type elementProps = {
  className?: string
  color1: string
  color2: string
  link: Link
  flip?: boolean
  center?: boolean
}

export default function Button({
  className,
  color1,
  color2,
  link,
  flip,
  center,
}: elementProps) {
  const [hover, setHover] = useState(false)
  const arrowSize = 14
  return (
    <div
      className="sm:mt-8 mt-6"
      style={{
        transform: center ? `translateX(${arrowSize}px)` : "",
      }}
    >
      <BloqmatterLink link={link}>
        <div style={{ filter: "drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5))" }}>
          <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{
              backgroundColor: color1,
              clipPath: flip
                ? hover
                  ? `polygon(0 50%, ${arrowSize * 2}px 0, 100% 0, 100% 100%, ${
                      arrowSize * 2
                    }px 100%)`
                  : `polygon(${arrowSize * 2}px 50%, ${
                      arrowSize * 2
                    }px 0, 100% 0, 100% 100%, ${arrowSize * 2}px 100%)`
                : hover
                ? `polygon(0 0, calc(100% - ${
                    arrowSize * 2
                  }px) 0, 100% 50%, calc(100% - ${
                    arrowSize * 2
                  }px) 100%, 0% 100%)`
                : `polygon(0 0, calc(100% - ${
                    arrowSize * 2
                  }px) 0, calc(100% - ${arrowSize * 2}px) 50%, calc(100% - ${
                    arrowSize * 2
                  }px) 100%, 0% 100%)`,
              transition: "clip-path 0.3s",
            }}
            className={`inline-block py-2 ${
              flip ? "pl-12 pr-4" : "pl-4 pr-12"
            } `}
          >
            <TextBasic className="font-titleAccent text-center" color={color2}>
              {link?.text}
            </TextBasic>
          </div>
        </div>
      </BloqmatterLink>
    </div>
  )
}
