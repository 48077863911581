import React from "react"

type Props = { hover: boolean }

export default function HoverBar({ hover }: Props) {
  return (
    <div
      className={`absolute transition-all duration-300  bottom-0 left-1/2 -translate-x-1/2 bg-white ${
        hover ? "opacity-100 w-full h-px" : "opacity-0 w-0 h-0"
      }`}
    ></div>
  )
}
