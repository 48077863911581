import React, { useContext, useEffect, useRef, useState } from "react"
import { getItemFromId } from "@rubendenbrok/bloqmatter-cms"
import getResponsiveImgSrc from "../../functions/getResponsiveImgSrc"
import { DataContext } from "../Layout"
import ResponsiveImg from "./ResponsiveImg"

type BgVideoProps = {
  videoId: string
  autoPlay?: boolean
}

export default function VideoWithNoControls({
  videoId,
  autoPlay,
}: BgVideoProps) {
  const { videoData, processedImageData } = useContext(DataContext)

  const videoRef = useRef<HTMLVideoElement>(null)
  const [loaded, setLoaded] = useState(false)
  const [posterSrc, setPosterSrc] = useState("")

  const videoObj = getItemFromId(videoId, videoData)
  const imageObj =
    videoObj && videoObj.thumbnail
      ? getItemFromId(videoObj.thumbnail, processedImageData)
      : null

  function handleLoad() {
    setLoaded(true)
    if (autoPlay) videoRef.current?.play()
  }

  useEffect(() => {
    if (videoRef.current?.readyState === 4) handleLoad()
    if (imageObj) setPosterSrc(getResponsiveImgSrc(imageObj))
    return () => {}
  }, [])

  console.log(videoObj)

  return (
    <>
      {videoObj && (
        <>
          <video
            className={"object-cover w-full h-auto"}
            muted={autoPlay}
            src={videoObj.src}
            loop
            ref={videoRef}
            onCanPlayThrough={handleLoad}
            poster={posterSrc}
          ></video>
          {videoObj.thumbnail && !loaded && (
            <ResponsiveImg image={videoObj.thumbnail} imageCrop />
          )}
        </>
      )}
    </>
  )
}
