import React, { useContext, useState } from "react"
import { Link, Page } from "@rubendenbrok/bloqmatter-cms"
import BloqmatterLink from "../atoms/BloqmatterLink"
import { DataContext } from "../Layout"
import MenuButton from "../atoms/MenuButton"
import { handleScrollTo } from "./DesktopHeader"

export default function MobileHeader() {
  const { headerData, footerData, thisPage, cssVariables } =
    useContext(DataContext)
  const [showMenu, setShowMenu] = useState(false)
  return (
    <>
      <div
        onClick={() => setShowMenu(true)}
        className="flex sm:hidden left-2 top-2 w-12 h-12  flex-col justify-between px-3 py-4 fixed transition-opacity bg-black rounded-md"
        style={{
          zIndex: 999,
          opacity: showMenu ? 0 : 1,
          pointerEvents: showMenu ? "none" : "auto",
        }}
      >
        <div className="w-full h-0.5 bg-white"></div>
        <div className="w-full h-0.5 bg-white"></div>
        <div className="w-full h-0.5 bg-white"></div>
      </div>
      <header
        className="block sm:hidden w-screen fixed h-screen left-0 z-50"
        style={{
          backgroundColor: cssVariables.colors[headerData.bgColor],
          color: cssVariables.colors[headerData.textColor],
          top: showMenu ? 0 : "-100vh",
          transition: "top 0.1s cubic-bezier(0.76, 0, 0.24, 1)",
        }}
      >
        <nav className="w-full flex flex-col items-start relative font-titleAccent h-screen">
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-between items-center h-[66vh]">
            <MenuButton
              small
              onClick={() => {
                handleScrollTo("About")
                setShowMenu(false)
              }}
            >
              About
            </MenuButton>
            <MenuButton
              small
              onClick={() => {
                handleScrollTo("Music")
                setShowMenu(false)
              }}
            >
              Music
            </MenuButton>
            <MenuButton
              small
              onClick={() => {
                handleScrollTo("Tour")
                setShowMenu(false)
              }}
            >
              Tour
            </MenuButton>
            <MenuButton
              small
              onClick={() => {
                handleScrollTo("Contact")
                setShowMenu(false)
              }}
            >
              Contact
            </MenuButton>
          </div>
          <div
            className="absolute top-4 left-4 font-titleAccent"
            onClick={() => setShowMenu(false)}
          >
            Close
          </div>
        </nav>
      </header>
    </>
  )
}

type menuItemProps = {
  isDropdown: boolean
  childMenuItems: any[]
  link: Link
  accentColor: number
  cssVariables: { [x: string]: string }
}

function MenuItem({
  isDropdown,
  childMenuItems,
  link,
  accentColor,
  cssVariables,
}: menuItemProps) {
  return (
    <>
      <BloqmatterLink link={link}>
        <div className="mx-6 my-4 text-3xl">{link.text}</div>
      </BloqmatterLink>
      {/* DROPDOWN */}
      {isDropdown && Array.isArray(childMenuItems) && (
        <div className="">
          {childMenuItems.map(item => {
            if (item.visibility === "always" || item.visibility === "mobile") {
              return (
                <BloqmatterLink key={item.id} link={item.link}>
                  <MenuChildItem
                    link={item.link}
                    cssVariables={cssVariables}
                    childMenuItems={childMenuItems}
                    isDropdown={isDropdown}
                    accentColor={accentColor}
                  />
                </BloqmatterLink>
              )
            }
          })}
        </div>
      )}
    </>
  )
}
type MenuLinkProps = {
  link: Link
  cssVariables: { [x: string]: string }
  isDropdown: boolean
  childMenuItems: any[]
  accentColor: number
}

function MenuChildItem({ link, cssVariables, accentColor }: MenuLinkProps) {
  return (
    <div className="ml-8 px-2 py-2 relative block text-lg ">{link.text}</div>
  )
}
