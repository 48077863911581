import React, { ReactNode, useContext } from "react"
import { Link as GatsbyLink } from "gatsby"
import { getItemFromId, Link, Page } from "@rubendenbrok/bloqmatter-cms"
import { DataContext } from "../Layout"

type LinkProps = {
  link: Link
  children?: ReactNode
  forceLocale?: string
}

export default function BloqmatterLink({
  link,
  children,
  forceLocale,
}: LinkProps) {
  const { allPages, localeData } = useContext(DataContext)
  function getUrlFromId(id: string, pages: Page[]) {
    const page = getItemFromId(id, pages)
    const localePrefix = forceLocale
      ? forceLocale
      : localeData.enableLocales && localeData.currentLocale
      ? `/${localeData.currentLocale}`
      : ""

    if (!page || page.isHomePage) return `${localePrefix}/`
    if (page.category === "mainPage") return `${localePrefix}/${page.slug}`
    return `${localePrefix}/${fixedEncodeURIComponent(page.category)}/${
      page.slug
    }`
  }

  function fixedEncodeURIComponent(str: string) {
    const newStr = str.split(" ").join("-")
    return encodeURIComponent(newStr)
      .replace(/[!'()*]/g, function (c) {
        return "%" + c.charCodeAt(0).toString(16)
      })
      .toLowerCase()
  }

  if (!link || (link.targetId && link.href)) return <></>
  return (
    <>
      {!children ? (
        link.external ? (
          <a href={link.href} target="_blank">
            {link.text}
          </a>
        ) : (
          <GatsbyLink to={getUrlFromId(link.targetId, allPages)}>
            {link.text}
          </GatsbyLink>
        )
      ) : link.external ? (
        <a href={link.href} target="_blank">
          {children}
        </a>
      ) : (
        <GatsbyLink to={getUrlFromId(link.targetId, allPages)}>
          {children}
        </GatsbyLink>
      )}
    </>
  )
}
