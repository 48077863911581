import React, { ReactNode } from "react"

type elementProps = {
  className?: string
  color?: string
  children?: ReactNode
  innerHtml?: string
  small?: boolean
  sizeClass?: string
}

export default function TextBasic({
  className,
  color,
  children,
  innerHtml,
  small,
  sizeClass,
}: elementProps) {
  return (
    <div
      style={{ color: color ? color : "inherit" }}
      className={`tracking-wide font-titleAccent   ${
        sizeClass
          ? sizeClass
          : small
          ? "text-md md:text-lg"
          : "text-lg md:text-xl"
      }  select-none ${className}`}
      dangerouslySetInnerHTML={innerHtml ? { __html: innerHtml } : undefined}
    >
      {children}
    </div>
  )
}
