/*
BESCHRIJVING:
Een block dat een video of afbeelding laat zien naast een tekst met titel en subtitel. 
De gebruiker kan kiezen of de media op mobile ook te zien moet zijn, als dat zo is komt die boven de tekst te staan.

De media bewaart altijd zijn oorspronkelijke aspect ratio, wordt niet gecropt, en wordt nooit groter dan zijn oorspronkelijke formaat.

DATA:
{
  title: string
  subtitle: string
  text: html string
  textAlign: 'start' | 'center' | 'end'
  mediaFormat: 'video' | 'image'
  video: string (video ID)
  image: string (image ID)
  switchOrder: boolean (Media verschijnt rechts ipv links van tekst if true)
  showMediaOnMobile: boolean
  buttons: CTA Input group - default: {buttonlink: Link, buttonColor1: color, buttonColor2: color}

}
*/

import React, { ChangeEvent, useContext, useState } from "react"
import { getItemFromId } from "@rubendenbrok/bloqmatter-cms"
import getCascadingColor from "../../functions/getCascadingColor"
import H3 from "../atoms/H3"
import H2 from "../atoms/H2"
import TextBasic from "../atoms/TextBasic"

import { DataContext } from "../Layout"
import BgVideo from "../molecules/BgVideo"
import Button from "../atoms/Button"
import ResponsiveImg from "../molecules/ResponsiveImg"
import useIsInScreen from "../../hooks/useIsInScreen"
import { fadeSlideIn } from "../../styles/animations"
import { DEFAULT_BLOCKOPTIONS_DEFAULTS } from "@rubendenbrok/bloqmatter-cms"

type props = any

export default function MediaTextBlock_Display({ blockContent }: props) {
  const {
    cssVariables,
    thisPage,
    siteOverviewData,
    videoData,
    processedImageData,
  } = useContext(DataContext)
  const videoObj = getItemFromId(blockContent.video, videoData)
  const imgObj = getItemFromId(blockContent.image, processedImageData)

  const [animRef, isVisible] = useIsInScreen("100px")
  const scrollAnimation = isVisible ? fadeSlideIn.show : fadeSlideIn.hide

  const textAlignClass =
    blockContent.textAlign === "start"
      ? "lg:text-left text-center"
      : blockContent.textAlign === "center"
      ? "text-center"
      : "lg:text-right text-center"

  return (
    <div
      className={`w-full relative flex flex-col justify-between items-center ${
        blockContent.switchOrder ? "lg:flex-row-reverse" : "lg:flex-row"
      }`}
    >
      <div
        className={`relative lg:w-3/5 mx-auto w-full ${
          blockContent.showMediaOnMobile ? "block" : "hidden lg:block"
        } ${
          blockContent.mediaFormat === "image"
            ? blockContent.switchOrder
              ? "lg:pr-20  px-6"
              : "lg:pl-20 px-6"
            : ""
        }`}
      >
        {blockContent.mediaFormat === "image" ? (
          <ResponsiveImg image={blockContent.image} />
        ) : (
          <BgVideo videoId={blockContent.video} />
        )}
      </div>

      <div
        ref={animRef}
        className={`block lg:w-[62%] w-full ${textAlignClass} md:px-20 px-6 ${
          blockContent.showMediaOnMobile ? "lg:mt-0 mt-6" : ""
        }`}
        style={{
          ...fadeSlideIn.transition,
          ...scrollAnimation,
        }}
      >
        {blockContent.title && (
          <H2
            color={
              thisPage
                ? getCascadingColor(
                    cssVariables,
                    siteOverviewData,
                    thisPage,
                    blockContent
                  ).title
                : "black"
            }
            className={`${textAlignClass} overflow-y-visible`}
          >
            {blockContent.title}
          </H2>
        )}
        {blockContent.subtitle && (
          <H3
            color={
              thisPage
                ? getCascadingColor(
                    cssVariables,
                    siteOverviewData,
                    thisPage,
                    blockContent
                  ).title
                : "black"
            }
            className={`${textAlignClass}  overflow-y-visible`}
          >
            {blockContent.subtitle}
          </H3>
        )}
        {blockContent.text && (
          <TextBasic
            color={
              thisPage
                ? getCascadingColor(
                    cssVariables,
                    siteOverviewData,
                    thisPage,
                    blockContent
                  ).text
                : "black"
            }
            className={`mt-10 ${textAlignClass}`}
            innerHtml={blockContent.text}
          ></TextBasic>
        )}

        {blockContent.buttons &&
          blockContent.buttons.map((button: any, index: number) => {
            return (
              <Button
                key={index}
                link={button.buttonLink}
                color1={cssVariables.colors[button.buttonColor1]}
                color2={cssVariables.colors[button.buttonColor2]}
                flip={blockContent.textAlign === "end"}
              ></Button>
            )
          })}
      </div>
    </div>
  )
}

export const MediaTextBlock_Inputs = {
  key: "mediaTextBlock",
  name: "Media / Text Block",
  description:
    "A block with an image or video, next to some text. Buttons or CTA's can also be added.",
  tags: ["Media", "Image", "Video", "Text", "CTA"],
  inputs: [
    {
      inputType: "expandableInputs",
      label: "Text",
      childInputs: [
        {
          inputType: "text",
          label: "Title",
          keyName: "title",
          defaultVal: "",
        },
        {
          inputType: "text",
          label: "Subtitle",
          keyName: "subtitle",
          defaultVal: "",
        },
        {
          inputType: "richText",
          label: "Text",
          keyName: "text",
          defaultVal: "",
        },
        {
          inputType: "radio",
          labelArr: ["Left", "Center", "Right"],
          valueArr: ["start", "center", "end"],
          label: "Text align",
          keyName: "textAlign",
          defaultVal: "center",
        },
      ],
    },
    {
      inputType: "expandableInputs",
      label: "Media",
      childInputs: [
        {
          inputType: "conditionalRadio",
          label: "Media format",
          keyName: "mediaFormat",
          labelArr: ["Video", "Image"],
          valueArr: ["video", "image"],
          childInputsArr: [
            [
              {
                inputType: "video",
                label: "Video",
                keyName: "video",
                defaultVal: "",
              },
            ],
            [
              {
                inputType: "image",
                label: "Image",
                keyName: "image",
                defaultVal: "",
              },
            ],
          ],
        },
      ],
    },
    {
      inputType: "checkbox",
      label: "Video right of text",
      keyName: "switchOrder",
    },
    {
      inputType: "checkbox",
      label: "Show video on mobile",
      keyName: "showMediaOnMobile",
    },
    { type: "group", inputGroupName: "ctaInputGroup" },
  ],
  defaults: {
    ...DEFAULT_BLOCKOPTIONS_DEFAULTS,
  },
}
