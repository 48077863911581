import React, { ReactNode, useState } from "react"

type Props = {
  children: ReactNode
  color: string
}

export default function HoverLines({ children, color }: Props) {
  const [hover, setHover] = useState(false)
  return (
    <div
      className="relative w-[fit-content]"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {children}
      <div
        className={`absolute top-0 left-0 h-px transition-all ease-out duration-500 ${
          hover ? "w-full" : "w-0"
        }`}
        style={{ backgroundColor: color }}
      ></div>
      <div
        className={`absolute bottom-0 right-0 h-px transition-all  ease-out duration-500  ${
          hover ? "w-full" : "w-0"
        }`}
        style={{ backgroundColor: color }}
      ></div>
    </div>
  )
}
