import React, { createContext, useEffect } from "react"
import { ImageData, Page, VideoData } from "@rubendenbrok/bloqmatter-cms"
import getCascadingColor, {
  cssVariables,
  inputData,
  WebsiteColorData,
} from "../functions/getCascadingColor"
import Footer from "./molecules/Footer"
import Header from "./header/Header"
import Seo from "./Seo"
import DisplayBlock_Container from "./displayBlocks/DisplayBlock_Container"
import useStore from "../store"
import PopupOverlay from "./atoms/PopupOverlay"
import CookieConsentPopup from "./organisms/CookieConsentPopup"
import HeroImageBlock_Display from "./displayBlocks/HeroImageBlock"
import TitleBlock_Display from "./displayBlocks/BasicTitleBlock"
import HeroCTABlock_Display from "./displayBlocks/HeroCTABlock"
import MediaTextBlock_Display from "./displayBlocks/MediaTextBlock"
import BasicTextBoxBlock_Display from "./displayBlocks/BasicTextBoxBlock"
import SocialButtonsBlock_Display from "./displayBlocks/SocialButtonsBlock"
import MediaMailingList_Display from "./displayBlocks/MediaMailingListBlock"
import HeroMenuBlock_Display from "./displayBlocks/HeroMenuBlock"
import BasicImageBlock_Display from "./displayBlocks/BasicImageBlock"
import MusicBlock_Display from "./displayBlocks/MusicBlock"
import MediaMailingListNew_Display from "./displayBlocks/MediaMailingListNewBlock"
import LinkBlock_Display from "./displayBlocks/BasicLinkBlock"
import VideoBlock_Display, {
  videoBlock_inputs,
} from "./displayBlocks/VideoBlock"

export interface WebsiteDataType {
  thisPage?: Page & inputData
  allPages: Page[]
  cssVariables: cssVariables
  footerData: { [x: string]: any }
  headerData: { [x: string]: any }
  processedImageData: ImageData[]
  videoData: VideoData[]
  seoData: { [x: string]: any }
  siteOverviewData: WebsiteColorData
  localeData: { [x: string]: any }
}

export interface LayoutProps extends WebsiteDataType {
  previewMode?: string
  hoveredBlockIndex?: number
  selectedBlockIndex?: number
  selectFunc?: Function
}

export const DataContext = createContext<WebsiteDataType>({
  allPages: [],
  cssVariables: { colors: [] },
  footerData: [],
  headerData: [],
  processedImageData: [],
  videoData: [],
  seoData: [],
  siteOverviewData: {
    colors_bg: 0,
    colors_text: 0,
    colors_title: 0,
  },
  localeData: {},
})

export default function Layout({
  previewMode,
  hoveredBlockIndex,
  selectedBlockIndex,
  selectFunc,
  thisPage,
  allPages,
  cssVariables,
  footerData,
  headerData,
  processedImageData,
  videoData,
  seoData,
  siteOverviewData,
  localeData,
}: LayoutProps) {
  const popupMsg = useStore(state => state.popupMsg)
  const displayBlocks = {
    heroImageBlock: HeroImageBlock_Display,
    titleBlock: TitleBlock_Display,
    heroCTABlock: HeroCTABlock_Display,
    mediaTextBlock: MediaTextBlock_Display,
    basicTextBlock: BasicTextBoxBlock_Display,
    socialButtonsBlock: SocialButtonsBlock_Display,
    mediaMailingListBlock: MediaMailingList_Display,
    heroMenuBlock: HeroMenuBlock_Display,
    basicImageBlock: BasicImageBlock_Display,
    musicBlock: MusicBlock_Display,
    mediaMailingListNewBlock: MediaMailingListNew_Display,
    linkBlock: LinkBlock_Display,
    videoBlock: VideoBlock_Display,
  }

  useEffect(() => {
    if (typeof window !== "undefined" && thisPage) {
      document.body.style.backgroundColor =
        cssVariables.colors[thisPage.pageOptions?.bgColor] ||
        cssVariables.colors[siteOverviewData.colors_bg]
    }
    return () => {}
  }, [])

  if (!thisPage) {
    return <></>
  }

  return (
    <DataContext.Provider
      value={{
        thisPage: thisPage,
        allPages: allPages,
        cssVariables: cssVariables,
        footerData: footerData,
        headerData: headerData,
        processedImageData: processedImageData,
        videoData: videoData,
        seoData: seoData,
        siteOverviewData: siteOverviewData,
        localeData: localeData,
      }}
    >
      <Seo />
      {seoData.enableAnalytics && <CookieConsentPopup />}
      <div
        id="layout"
        className={`w-full h-full relative transform-none overflow-x-hidden ${
          previewMode === "mobile" ? "hidescrollbar" : ""
        }`}
        style={{
          minHeight: "100vh",
          backgroundColor: getCascadingColor(
            cssVariables,
            siteOverviewData,
            thisPage
          ).bg,
          color: getCascadingColor(cssVariables, siteOverviewData, thisPage)
            .text,
        }}
      >
        {thisPage.showHeader && <Header />}
        {thisPage.content.map((block, index) => {
          if (!(block.key in displayBlocks)) {
            return
          }
          const DisplayBlock =
            displayBlocks[block.key as keyof typeof displayBlocks]
          return (
            <DisplayBlock_Container
              key={index}
              index={index}
              blockContent={block.blockContent}
              hovered={hoveredBlockIndex === index}
              previewMode={previewMode}
              selectFunc={selectFunc}
            >
              {DisplayBlock && (
                <DisplayBlock blockContent={block.blockContent} index={index} />
              )}
            </DisplayBlock_Container>
          )
        })}
        {thisPage.showFooter && footerData && <Footer />}
      </div>
      {popupMsg && <PopupOverlay />}
    </DataContext.Provider>
  )
}
