import React, { useContext } from "react"
import getCascadingColor from "../../functions/getCascadingColor"
import H1 from "../atoms/H1"
import H2 from "../atoms/H2"
import { DEFAULT_BLOCKOPTIONS_DEFAULTS } from "@rubendenbrok/bloqmatter-cms"

import { DataContext } from "../Layout"
import { BsArrowLeft } from "react-icons/bs"
import BloqmatterLink from "../atoms/BloqmatterLink"

/*
BESCHRIJVING:
Basis titel, kan ook als spacing gebruikt worden

DATA:
{
  title: string
  subtitle: string
  text: html string
  textHorizontalPos: 'start' | 'center' | 'end'
  textVerticalPos: 'start' | 'center' | 'end'
  video: string (video ID)
}
*/

type props = any

export default function LinkBlock_Display({ blockContent }: props) {
  const { cssVariables, thisPage, siteOverviewData } = useContext(DataContext)
  console.log(blockContent)
  return (
    <div className="px-6 text-xl font-titleAccent ">
      <BloqmatterLink link={blockContent.link}>
        <div className="flex items-center justify-start md:-ml-8 md:justify-center">
          <BsArrowLeft size="20px" />
          <div className="ml-4 text-2xl cursor-pointer hover:underline">
            {blockContent.link?.text}
          </div>
        </div>
      </BloqmatterLink>
    </div>
  )
}

export const LinkBlock_Inputs = {
  key: "linkBlock",
  name: "Basic Link Block",
  tags: ["Text", "Spacing", "Title"],

  inputs: [
    {
      inputType: "link",
      label: "Link:",
      keyName: "link",
    },
  ],
  defaults: {
    titleText: "New Title",
    titleTextAlign: "center",
    ...DEFAULT_BLOCKOPTIONS_DEFAULTS,
  },
}
