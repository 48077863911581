import React, { useContext } from "react"
import getCascadingColor from "../../functions/getCascadingColor"
import useIsInScreen from "../../hooks/useIsInScreen"
import { fadeSlideIn } from "../../styles/animations"
import BloqmatterLink from "../atoms/BloqmatterLink"
import Button from "../atoms/Button"
import H2 from "../atoms/H2"
import TextBasic from "../atoms/TextBasic"
import { DataContext } from "../Layout"
import { DEFAULT_BLOCKOPTIONS_DEFAULTS } from "@rubendenbrok/bloqmatter-cms"

type props = any

/*
Just a basic text box + title and CTA

DATA {
title : string
text : string (rich text html)
textAlign: 'start'|'left'|'center'
CTA Input group 
    default: 
    {buttonlink: Link, 
    buttonColor1: color, 
    buttonColor2: color}
*/

export default function BasicTextBoxBlock_Display({ blockContent }: props) {
  const { cssVariables, thisPage, siteOverviewData } = useContext(DataContext)
  const [animRef, isVisible] = useIsInScreen("100px")
  const textAnimation = isVisible ? fadeSlideIn.show : fadeSlideIn.hide

  const textAlignClass =
    blockContent.textAlign === "start"
      ? "text-left"
      : blockContent.textAlign === "center"
      ? "text-left md:text-center"
      : "text-right"

  return (
    <>
      {/* //DESKTOP VERSION */}
      <div
        ref={animRef}
        className={` flex-col flex   px-6  ${
          blockContent.textAlign === "start"
            ? "items-start"
            : blockContent.textAlign === "center"
            ? "items-start md:items-center"
            : "items-end"
        }`}
        style={{
          marginLeft: blockContent.textAlign === "start" ? 0 : "auto",
          marginRight: blockContent.textAlign === "end" ? 0 : "auto",
          ...fadeSlideIn.transition,
          ...textAnimation,
        }}
      >
        <H2
          color={
            thisPage
              ? getCascadingColor(
                  cssVariables,
                  siteOverviewData,
                  thisPage,
                  blockContent
                ).title
              : ""
          }
          className={`${textAlignClass} overflow-y-visible font-titleAccent `}
        >
          {blockContent.title}
        </H2>
        <TextBasic
          className={`${textAlignClass} pt-8`}
          innerHtml={blockContent.text}
        ></TextBasic>
        {blockContent.buttons &&
          blockContent.buttons.map((button: any, index: number) => {
            return (
              <Button
                link={button.buttonLink}
                color1={cssVariables.colors[button.buttonColor1]}
                color2={cssVariables.colors[button.buttonColor2]}
                flip={blockContent.textAlign === "end"}
                center={blockContent.textAlign === "center"}
              ></Button>
            )
          })}
      </div>
    </>
  )
}

export const basicTextBoxBlock_inputs = {
  key: "basicTextBlock",
  name: "Basic Text Block",
  inputs: [
    {
      inputType: "text",
      label: "Title",
      keyName: "title",
    },
    {
      inputType: "richText",
      label: "Text",
      keyName: "text",
    },
    {
      inputType: "radio",
      labelArr: ["Left", "Center", "Right"],
      valueArr: ["start", "center", "end"],
      label: "Text align",
      keyName: "textAlign",
      defaultVal: "center",
    },
    { type: "group", inputGroupName: "ctaInputGroup" },
  ],
  defaults: {
    ...DEFAULT_BLOCKOPTIONS_DEFAULTS,
  },
}
