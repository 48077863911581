/*
BESCHRIJVING:
Een block dat een video of afbeelding laat zien naast een tekst met titel en subtitel. 
De gebruiker kan kiezen of de media op mobile ook te zien moet zijn, als dat zo is komt die boven de tekst te staan.

De media bewaart altijd zijn oorspronkelijke aspect ratio, wordt niet gecropt, en wordt nooit groter dan zijn oorspronkelijke formaat.

DATA:
{
  title: string
  subtitle: string
  text: html string
  textAlign: 'start' | 'center' | 'end'
  mediaFormat: 'video' | 'image'
  video: string (video ID)
  image: string (image ID)
  switchOrder: boolean (Media verschijnt rechts ipv links van tekst if true)
  showMediaOnMobile: boolean
  buttons: CTA Input group - default: {buttonlink: Link, buttonColor1: color, buttonColor2: color}

}
*/

import React, {
  ChangeEvent,
  FormEvent,
  useContext,
  useRef,
  useState,
} from "react"
import { getItemFromId } from "@rubendenbrok/bloqmatter-cms"
import getCascadingColor from "../../functions/getCascadingColor"
import H3 from "../atoms/H3"
import H2 from "../atoms/H2"
import TextBasic from "../atoms/TextBasic"

import { DataContext } from "../Layout"
import BgVideo from "../molecules/BgVideo"
import Button from "../atoms/Button"
import ResponsiveImg from "../molecules/ResponsiveImg"
import useIsInScreen from "../../hooks/useIsInScreen"
import { fadeSlideIn } from "../../styles/animations"
import { DEFAULT_BLOCKOPTIONS_DEFAULTS } from "@rubendenbrok/bloqmatter-cms"
import HoverLines from "../atoms/HoverLines"

type props = any

export default function MediaMailingListNew_Display({
  blockContent,
  index,
}: props) {
  const {
    cssVariables,
    thisPage,
    siteOverviewData,
    videoData,
    processedImageData,
  } = useContext(DataContext)
  const videoObj = getItemFromId(blockContent.video, videoData)
  const [inputVal, setInputVal] = useState("")
  const [inputXVal, setInputXVal] = useState("")

  const [subscribeMsg, setSubscribeMsg] = useState("")
  const emailRef = useRef("")
  const fakeRef = useRef("")
  const formRef = useRef<HTMLFormElement | null>(null)

  const imgObj = getItemFromId(blockContent.image, processedImageData)

  const [animRef, isVisible] = useIsInScreen("100px")
  const scrollAnimation = isVisible ? fadeSlideIn.show : fadeSlideIn.hide

  function handleXChange(e: ChangeEvent<HTMLInputElement>) {
    fakeRef.current = e.target.value
    setInputXVal(e.target.value)
  }
  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    emailRef.current = e.target.value
    setInputVal(e.target.value)
  }

  async function onNewsletterSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()

    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    if (!regex.test(emailRef.current)) {
      setSubscribeMsg("Sorry, that doesn't seem to be a valid email adress...")
      return
    }

    if (!fakeRef.current) {
      const url = `/.netlify/functions/newsletterSubscribe?email=${emailRef.current}`
      try {
        setSubscribeMsg("Processing...")
        const response = await fetch(url)
        if (response.status === 200) {
          setSubscribeMsg("Thank you, you'll be the first to hear new music!")
          setInputVal("")
          emailRef.current = ""
        } else {
          setSubscribeMsg("Something went wrong, please try again later")
        }
      } catch (e) {
        console.warn(e)
        setSubscribeMsg("Something went wrong, please try again later")
      }
    }
    return false
  }

  return (
    <div
      className={`w-full relative flex flex-col justify-center items-center`}
    >
      <div
        ref={animRef}
        className={`w-full  flex flex-col items-start md:items-center md:px-20 px-5 ${
          blockContent.showMediaOnMobile ? "lg:mt-0 mt-6" : ""
        }`}
        style={{
          ...fadeSlideIn.transition,
          ...scrollAnimation,
        }}
      >
        {blockContent.title && (
          <H2
            color={
              thisPage
                ? getCascadingColor(
                    cssVariables,
                    siteOverviewData,
                    thisPage,
                    blockContent
                  ).title
                : "black"
            }
            className={`text-left md:text-center`}
          >
            {blockContent.title}
          </H2>
        )}
        {blockContent.subtitle && (
          <TextBasic
            color={
              thisPage
                ? getCascadingColor(
                    cssVariables,
                    siteOverviewData,
                    thisPage,
                    blockContent
                  ).title
                : "black"
            }
            className={`text-left md:text-center  overflow-y-visible`}
          >
            {blockContent.subtitle}
          </TextBasic>
        )}

        <form
          ref={formRef}
          name={`emailForm#${index}`}
          data-netlify="true"
          netlify-honeypot="extra"
          action="/thanks-for-subcribing"
          className="flex flex-col md:items-center items-start mt-4"
          onSubmit={onNewsletterSubmit}
        >
          <>
            <div className="flex">
              <input
                onChange={handleChange}
                value={inputVal}
                type="email"
                name="email"
                placeholder="Your email adress"
                className="block outline-none h-12 text-white bg-slate-900 focus:bg-slate-700 transition-colors py-2 font-basic text-xl w-72 max-w-[90vw] md:mb-12 text-left pl-5 pr-5 md:pr-6 rounded-l-full rounded-r-full md:rounded-r-none"
              ></input>
              <input
                onChange={handleXChange}
                value={inputXVal}
                type="text"
                name="extra"
                placeholder="Your email adress"
                className=" block opacity-0 w-0 h-0 absolute outline-none text-white bg-slate-200 focus:bg-slate-700 transition-colors py-2 font-basic text-xl text-left rounded-l-full rounded-r-full md:rounded-r-none"
              ></input>
              <button className="hidden md:block cursor-pointer hover:bg-indigo-900 transition-colors rounded-full font-basic text-xl h-12 px-4 p-0 -ml-6 w-32 bg-indigo-700 tracking-widest">
                Join
              </button>
            </div>
            <button className="block md:hidden cursor-pointer hover:bg-indigo-900 transition-colors rounded-full font-basic text-lg h-10 px-4 p-0 mt-4 mb-10 w-32 bg-indigo-700 tracking-widest">
              Join
            </button>
          </>

          <H3>{subscribeMsg}</H3>
        </form>
      </div>
    </div>
  )
}

export const MediaMailingListNewBlock_Inputs = {
  key: "mediaMailingListNewBlock",
  name: "New Media / Mailing list subscribe Block",
  description:
    "A block with an image or video, next to some text and an option to subscribe for a mailing list.",
  tags: ["Media", "Image", "Video", "Text", "CTA"],
  inputs: [
    {
      inputType: "expandableInputs",
      label: "Text",
      childInputs: [
        {
          inputType: "text",
          label: "Title",
          keyName: "title",
          defaultVal: "",
        },
        {
          inputType: "text",
          label: "Subtitle",
          keyName: "subtitle",
          defaultVal: "",
        },
      ],
    },
  ],
  defaults: {
    ...DEFAULT_BLOCKOPTIONS_DEFAULTS,
  },
}
