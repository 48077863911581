import React, { useContext } from "react"
import { getItemFromId } from "@rubendenbrok/bloqmatter-cms"
import getCascadingColor from "../../functions/getCascadingColor"
import { getAbsolutePositionStyle } from "../../styles/util"
import H1 from "../atoms/H1"
import H2 from "../atoms/H2"
import { DEFAULT_BLOCKOPTIONS_DEFAULTS } from "@rubendenbrok/bloqmatter-cms"

import { DataContext } from "../Layout"
import BgVideo from "../molecules/BgVideo"
import ResponsiveBgImg from "../molecules/ResponsiveBgImg"
import H3 from "../atoms/H3"
import HoverLines from "../atoms/HoverLines"
import TextBasic from "../atoms/TextBasic"

/*
BESCHRIJVING:
Een Hero video die altijd de breedte van het scherm aanhoudt, met een eventuele tekst erover.

DATA:
{
  title: string
  subtitle: string
  text: html string
  textHorizontalPos: 'start' | 'center' | 'end'
  textVerticalPos: 'start' | 'center' | 'end'
  video: string (video ID)


}
*/

type props = any

export default function HeroCTABlock_Display({ blockContent }: props) {
  const { cssVariables, thisPage, siteOverviewData, processedImageData } =
    useContext(DataContext)
  const imgObj = getItemFromId(blockContent.image, processedImageData)
  // const ratio = videoObj?.metadata.height / videoObj?.metadata.width

  const textboxPositionStyle = getAbsolutePositionStyle(
    blockContent.textHorizontalPos,
    blockContent.textVerticalPos,
    "80px"
  )

  return (
    <>
      <div
        className="w-screen  h-full absolute top-0 left-1/2 -translate-x-1/2 opacity-50"
        style={{
          background:
            "linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)",
        }}
      ></div>
      <div
        className="w-full relative b"
        // style={{ paddingBottom: ratio * 100 + "%" }}
      >
        <div className="text-center flex flex-col items-center mx-auto pt-[15vh] mb-4">
          {blockContent.title && (
            <H1
              className={` fadeInSlow text-center lg:mb-6 mb-4`}
              style={{
                color: thisPage
                  ? getCascadingColor(
                      cssVariables,
                      siteOverviewData,
                      thisPage,
                      blockContent
                    ).title
                  : "black",
                animationDelay: "0.5s",
              }}
            >
              {blockContent.title}
            </H1>
          )}
          {blockContent.subtitle && (
            <TextBasic
              color={
                thisPage
                  ? getCascadingColor(
                      cssVariables,
                      siteOverviewData,
                      thisPage,
                      blockContent
                    ).title
                  : "black"
              }
              className={`overflow-y-visible font-titleAccent -mt-2 mb-8`}
            >
              {blockContent.subtitle}
            </TextBasic>
          )}
          {blockContent.ctaText && (
            <HoverLines color={cssVariables.colors[blockContent.colors_text]}>
              <a
                className="block font-titleAccent fadeInSlow "
                style={{ animationDelay: "1.5s" }}
                href={blockContent.ctaTarget}
                target="_blank"
              >
                <H3>{blockContent.ctaText}</H3>
              </a>
            </HoverLines>
          )}
        </div>
        <div className="w-3/4 xl:w-1/2 mx-auto relative  overflow-hidden aspect-square">
          <ResponsiveBgImg image={blockContent.image} fx="" />
        </div>
      </div>
    </>
  )
}

export const HeroCTABlock_Inputs = {
  key: "heroCTABlock",
  name: "Hero CTA Block",
  tags: ["Video", "Media", "Hero", "Title"],
  description: "A hero video, with an optional title overlayed on top of it.",
  inputs: [
    {
      inputType: "expandableInputs",
      label: "Text",
      childInputs: [
        {
          inputType: "text",
          label: "Title",
          keyName: "title",
          defaultVal: "",
        },
        {
          inputType: "text",
          label: "Subtitle",
          keyName: "subtitle",
          defaultVal: "",
        },
        {
          inputType: "text",
          label: "CTA text",
          keyName: "ctaText",
          defaultVal: "",
        },
        {
          inputType: "text",
          label: "CTA target",
          keyName: "ctaTarget",
          defaultVal: "",
        },
      ],
    },
    {
      inputType: "expandableInputs",
      label: "Image",
      childInputs: [
        {
          inputType: "image",
          label: "Image",
          keyName: "image",
          defaultVal: "",
        },
      ],
    },
  ],
  defaults: {
    ...DEFAULT_BLOCKOPTIONS_DEFAULTS,
  },
}
