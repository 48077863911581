import React, { useContext } from "react"
import getCascadingColor from "../../functions/getCascadingColor"
import H1 from "../atoms/H1"
import H2 from "../atoms/H2"
import { DEFAULT_BLOCKOPTIONS_DEFAULTS } from "@rubendenbrok/bloqmatter-cms"

import { DataContext } from "../Layout"

/*
BESCHRIJVING:
Basis titel, kan ook als spacing gebruikt worden

DATA:
{
  title: string
  subtitle: string
  text: html string
  textHorizontalPos: 'start' | 'center' | 'end'
  textVerticalPos: 'start' | 'center' | 'end'
  video: string (video ID)
}
*/

type props = any

export default function TitleBlock_Display({ blockContent }: props) {
  const { cssVariables, thisPage, siteOverviewData } = useContext(DataContext)

  const textAlignClass = "text-left md:text-center"
  return (
    <div className="md:px-20 sm:px-10 px-6" id={blockContent.subtitleText}>
      <H1
        color={
          thisPage
            ? getCascadingColor(
                cssVariables,
                siteOverviewData,
                thisPage,
                blockContent
              ).title
            : "black"
        }
        className={`${textAlignClass} overflow-y-visible`}
      >
        {blockContent.titleText}
      </H1>
      <H2
        color={
          thisPage
            ? getCascadingColor(
                cssVariables,
                siteOverviewData,
                thisPage,
                blockContent
              ).title
            : "black"
        }
        className={`${textAlignClass} overflow-y-visible font-titleAccent`}
      >
        {blockContent.subtitleText}
      </H2>
    </div>
  )
}

export const BasicTitleBlock_Inputs = {
  key: "titleBlock",
  name: "Basic Title Block",
  tags: ["Text", "Spacing", "Title"],
  description:
    "A basic title, can also be left empty to be used as an spacing block.",
  inputs: [
    {
      inputType: "text",
      label: "Title:",
      keyName: "titleText",
      defaultVal: "Title",
    },
    {
      inputType: "text",
      label: "Subtitle:",
      keyName: "subtitleText",
      defaultVal: "Title",
    },
    {
      inputType: "radio",
      label: "Text Align:",
      keyName: "titleTextAlign",
      defaultVal: "left",
      labelArr: ["Left", "Center", "Right"],
      valueArr: ["start", "center", "end"],
    },
  ],
  defaults: {
    titleText: "New Title",
    titleTextAlign: "center",
    ...DEFAULT_BLOCKOPTIONS_DEFAULTS,
  },
}
