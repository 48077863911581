import React, { ReactNode } from "react"

type elementProps = {
  className: string
  color: string
  children?: ReactNode
  setInnerHtml?: string
}

export default function H1({
  className,
  color,
  children,
  setInnerHtml,
}: elementProps) {
  return (
    <h1
      style={{ color: color ? color : "inherit" }}
      className={`font-titleAccent lg:text-[7rem] md:text-7xl sm:text-7xl text-5xl m-0 -mt-4 select-none tracking-wide ${className}`}
      //dangerouslySetInnerHTML={setInnerHtml ? { __html: setInnerHtml } : null}
    >
      {children}
    </h1>
  )
}
