import React, { useContext } from "react"
import getCascadingColor from "../../functions/getCascadingColor"
import useIsInScreen from "../../hooks/useIsInScreen"
import { fadeSlideIn } from "../../styles/animations"
import BloqmatterLink from "../atoms/BloqmatterLink"
import Button from "../atoms/Button"
import H2 from "../atoms/H2"
import TextBasic from "../atoms/TextBasic"
import { DataContext } from "../Layout"
import { DEFAULT_BLOCKOPTIONS_DEFAULTS } from "@rubendenbrok/bloqmatter-cms"

type props = any

export default function SocialButtonsBlock_Display({ blockContent }: props) {
  const { cssVariables, thisPage, siteOverviewData } = useContext(DataContext)
  const [animRef, isVisible] = useIsInScreen("100px")
  const textAnimation = isVisible ? fadeSlideIn.show : fadeSlideIn.hide

  return (
    <>
      {/* //DESKTOP VERSION */}
      <div
        ref={animRef}
        className={`max-w-xs w-2/3  mx-auto  flex justify-between items-center filter invert`}
        style={{
          ...fadeSlideIn.transition,
          ...textAnimation,
        }}
      >
        <a
          className="sm:w-10 w-8 sm:h-10 h-8 opacity-40 hover:opacity-100 transition-opacity"
          href={blockContent.spotifyLink}
          target="_blank"
        >
          <img
            style={{
              ...fadeSlideIn.transition,
              ...textAnimation,
            }}
            src="./img/spotify.png"
          ></img>
        </a>
        <a
          className="sm:w-10 w-8 sm:h-10 h-8 opacity-40 hover:opacity-100 transition-opacity"
          href={blockContent.appleMusicLink}
          target="_blank"
        >
          <img
            src="./img/applemusic.png"
            style={{
              ...fadeSlideIn.transition,
              ...textAnimation,
              transitionDelay: "0.4s",
            }}
          ></img>
        </a>
        <a
          className="sm:w-10 w-8 sm:h-10 h-8 opacity-40 hover:opacity-100 transition-opacity"
          href={blockContent.instagramLink}
          target="_blank"
        >
          <img
            src="./img/instagram.png"
            style={{
              ...fadeSlideIn.transition,
              ...textAnimation,
              transitionDelay: "0.8s",
            }}
          ></img>
        </a>
      </div>
    </>
  )
}

export const socialButtonsBlock_inputs = {
  key: "socialButtonsBlock",
  name: "Social Buttons Block",
  inputs: [
    {
      inputType: "text",
      label: "Spotify link",
      keyName: "spotifyLink",
    },
    {
      inputType: "text",
      label: "Apple Music link",
      keyName: "appleMusicLink",
    },
    {
      inputType: "text",
      label: "Instagram link",
      keyName: "instagramLink",
    },
  ],
  defaults: {
    ...DEFAULT_BLOCKOPTIONS_DEFAULTS,
  },
}
