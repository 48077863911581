import React, { ReactElement, ReactNode, useState } from "react"
import HoverBar from "./HoverBar"

type Props = {
  onClick?: () => void
  children: ReactNode
  small?: boolean
}

export default function MenuButton({ onClick, children, small }: Props) {
  const [hover, setHover] = useState(false)
  return (
    <button
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
      className={`relative font-titleAccent ${
        small ? "text-2xl" : "text-2xl sm:text-2xl lg:text-3xl"
      } tracking-widest  cursor-pointer mt-6 sm:mt-0`}
    >
      {children}
      <HoverBar hover={hover} />
    </button>
  )
}
