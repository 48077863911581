import React, { ReactNode, useContext, useRef, useState } from "react"
import getCascadingColor from "../../functions/getCascadingColor"
import useIsInScreen from "../../hooks/useIsInScreen"
import { fadeSlideIn } from "../../styles/animations"
import BloqmatterLink from "../atoms/BloqmatterLink"
import Button from "../atoms/Button"
import H2 from "../atoms/H2"
import TextBasic from "../atoms/TextBasic"
import { DataContext } from "../Layout"
import { DEFAULT_BLOCKOPTIONS_DEFAULTS } from "@rubendenbrok/bloqmatter-cms"
import ResponsiveImg from "../molecules/ResponsiveImg"
import H3 from "../atoms/H3"
import HoverBar from "../atoms/HoverBar"
import { BsPlayFill, BsArrowLeft } from "react-icons/bs"

type props = any

/*
Just a basic text box + title and CTA

DATA {
title : string
text : string (rich text html)
textAlign: 'start'|'left'|'center'
CTA Input group 
    default: 
    {buttonlink: Link, 
    buttonColor1: color, 
    buttonColor2: color}
*/

export default function MusicBlock_Display({ blockContent }: props) {
  const { cssVariables, thisPage, siteOverviewData } = useContext(DataContext)
  const [animRef, isVisible] = useIsInScreen("100px")

  const [show, setShow] = useState(true)
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)

  function triggerSongHide(i: number | null) {
    timerRef.current = setTimeout(() => triggerSongShow(i), 310)
    setShow(false)
  }

  function triggerSongShow(i: number | null) {
    setSelectedIndex(i)
    setShow(true)
  }

  const selectedSong =
    selectedIndex !== null ? blockContent.songs[selectedIndex] : {}
  return (
    <>
      <div
        id="Music"
        className="px-6 flex items-center justify-between w-full relative"
      >
        <div
          className={`${
            show && selectedIndex === null
              ? "opcity-100"
              : "opacity-0 md:opacity-100"
          } transition-opacity duration-300 flex flex-col md:items-start my-20 py-8 md:py-0 items-center md:w-44 w-full shrink-0 `}
        >
          <div className="text-center block md:hidden mb-8">
            <H2 sizeClass="text-xl" className="">
              Explore Rubens Music
            </H2>
            <TextBasic>Choose a song below</TextBasic>
          </div>

          {blockContent.songs?.map((song: any, index: number) => {
            return (
              <SongButton
                key={index}
                onClick={
                  song.notClickable ? null : () => triggerSongHide(index)
                }
              >
                {song.title}
              </SongButton>
            )
          })}
        </div>

        <div
          className={`${
            show && selectedIndex !== null
              ? "opcity-100"
              : "opacity-0 pointer-events-none"
          } transition-opacity flex flex-col items-center md:hidden absolute left-0 top-1/2 -translate-y-1/2 text-center w-screen px-6 py-8`}
        >
          <H2 sizeClass="text-4xl">{selectedSong.title}</H2>
          <div className="w-48 h-48 mt-2 mb-6">
            <ResponsiveImg image={selectedSong.cover}></ResponsiveImg>
          </div>
          <TextBasic small innerHtml={selectedSong.text} />
          <div className="flex w-full justify-center  mt-4">
            <SongLink href={selectedSong.spotifyLink}>
              <div className="flex items-center">
                <BsPlayFill size={"20px"} color={cssVariables.colors[1]} />
                <div>Listen</div>
              </div>
            </SongLink>
            {/* <div className="w-12"></div>
            <SongLink href={selectedSong.youtubeLink}>
              <div className="flex items-center">
                <BsPlayFill size={"20px"} color={cssVariables.colors[1]} />
                <TextBasic>Youtube</TextBasic>
              </div>
            </SongLink> */}
          </div>
          <div
            onClick={() => triggerSongHide(null)}
            className="flex cursor-pointer items-center mt-8"
          >
            <BsArrowLeft size="20px" /> <i className="block ml-1">Back</i>
          </div>
        </div>

        <div
          className={`md:flex hidden text-right flex-col items-end justify-center h-[720px] transition-opacity duration-300 ${
            show ? "opacity-100" : "opacity-0"
          }`}
        >
          {selectedIndex === null ? (
            <>
              <H2 sizeClass="text-5xl" className="">
                Explore Rubens Music
              </H2>
              <TextBasic>Please choose a song</TextBasic>
            </>
          ) : (
            <>
              <H2 sizeClass="text-4xl">{selectedSong.title}</H2>
              <div className="w-72 h-72 my-2">
                <ResponsiveImg image={selectedSong.cover}></ResponsiveImg>
              </div>
              <TextBasic small innerHtml={selectedSong.text} />
              <div className="flex mt-8">
                <SongLink href={selectedSong.spotifyLink}>
                  <div className="flex items-center">
                    <BsPlayFill size={"20px"} color={cssVariables.colors[1]} />
                    <div>Listen</div>
                  </div>
                </SongLink>
                {/* <div className="w-8"></div>
                <SongLink href={selectedSong.youtubeLink}>
                  <div className="flex items-center">
                    <BsPlayFill size={"20px"} color={cssVariables.colors[1]} />
                    <TextBasic>Youtube</TextBasic>
                  </div>
                </SongLink> */}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

type SongLinkProps = {
  children: ReactNode
  href: string
}

function SongLink({ children, href }: SongLinkProps) {
  const [hover, setHover] = useState(false)
  return (
    <a
      target="_blank"
      href={href}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`relative  font-titleAccent text-xl tracking-widest h-8 mb-2 cursor-pointer`}
    >
      {children}
      <HoverBar hover={hover} />
    </a>
  )
}
type SongButtonProps = {
  onClick: (() => void) | null
  children: ReactNode
}

function SongButton({ onClick, children }: SongButtonProps) {
  const [hover, setHover] = useState(false)
  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick ? onClick : () => {}}
      className={`${
        onClick ? "cursor-pointer" : "italic opacity-60"
      } relative font-titleAccent text-xl md:text-xl tracking-wide lg:text-xl h-8 mb-3 md:mb-2 `}
    >
      {children}
      {onClick && <HoverBar hover={hover} />}
    </div>
  )
}

export const musicBlock_inputs = {
  key: "musicBlock",
  name: "Music Block",
  inputs: [
    {
      inputType: "inputArray",
      label: "Songs",
      keyName: "songs",
      itemName: "song",
      childNameKey: "title",
      childInputs: [
        {
          inputType: "checkbox",
          label: "Not clickable",
          keyName: "notClickable",
        },
        { inputType: "text", keyName: "title", label: "Song title" },
        { inputType: "image", keyName: "cover", label: "Album cover" },
        { inputType: "richText", keyName: "text", label: "Description" },
        { inputType: "text", keyName: "spotifyLink", label: "Spotify link" },
        { inputType: "text", keyName: "youtubeLink", label: "Youtube link" },
      ],
    },
  ],
  defaults: {
    ...DEFAULT_BLOCKOPTIONS_DEFAULTS,
  },
}
