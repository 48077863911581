import React, { useContext } from "react"
import getCascadingColor from "../../functions/getCascadingColor"
import useIsInScreen from "../../hooks/useIsInScreen"
import { fadeSlideIn } from "../../styles/animations"
import BloqmatterLink from "../atoms/BloqmatterLink"
import Button from "../atoms/Button"
import H2 from "../atoms/H2"
import TextBasic from "../atoms/TextBasic"
import { DataContext } from "../Layout"
import { DEFAULT_BLOCKOPTIONS_DEFAULTS } from "@rubendenbrok/bloqmatter-cms"
import VideoWithControls from "../molecules/VideoWithControls"
import VideoWithNoControls from "../molecules/VideoWithNoControls"
import H3 from "../atoms/H3"

type props = any

/*
Just a basic text box + title and CTA

DATA {
title : string
text : string (rich text html)
textAlign: 'start'|'left'|'center'
CTA Input group 
    default: 
    {buttonlink: Link, 
    buttonColor1: color, 
    buttonColor2: color}
*/

export default function VideoBlock_Display({ blockContent }: props) {
  const { cssVariables, thisPage, siteOverviewData } = useContext(DataContext)
  const [animRef, isVisible] = useIsInScreen("100px")
  const textAnimation = isVisible ? fadeSlideIn.show : fadeSlideIn.hide
  console.log(blockContent.video)
  return (
    <>
      {/* //DESKTOP VERSION */}
      <div className="w-full px-6">
        <div
          className={`w-full border-white border-[1px] mt-4 relative`}
          style={{ boxShadow: "0 0 10px white" }}
        >
          <VideoWithNoControls videoId={blockContent?.video} autoPlay />
          <a
            target="_blank"
            href="https://manifoldmusic.netlify.app/"
            className="absolute w-full h-full left-0 top-0 flex flex-col items-center justify-center bg-opacity-0 hover:bg-opacity-20 bg-white transition-all cursor-pointer duration-300 opacity-75 hover:opacity-100"
          >
            <H3 className="text-center mb-4 ">Click to enter Manifold</H3>
          </a>
        </div>
      </div>
    </>
  )
}

export const videoBlock_inputs = {
  key: "videoBlock",
  name: "Video Block",
  inputs: [
    {
      inputType: "video",
      label: "Video",
      keyName: "video",
    },
  ],
  defaults: {
    ...DEFAULT_BLOCKOPTIONS_DEFAULTS,
  },
}
