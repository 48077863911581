import React, { useContext, useEffect, useRef, useState } from "react"
import { getItemFromId, ImageData } from "@rubendenbrok/bloqmatter-cms"
import useIntersection from "../../hooks/useInterSection"
import { DataContext } from "../Layout"

type ResponsiveImgProps = {
  image: string
  imageCrop?: boolean
  yFactor?: number
  fx?: string
}

export default function ResponsiveImg({
  image,
  imageCrop = true,
  fx,
}: ResponsiveImgProps) {
  const { processedImageData } = useContext(DataContext)
  const imageObj = getItemFromId<ImageData>(image, processedImageData)
  if (imageObj) {
    return (
      <div className="overflow-hidden w-full h-full relative">
        {fx === "zoom" ? (
          <IntersectionWrapper image={imageObj} imageCrop={imageCrop} fx={fx} />
        ) : (
          <Image image={imageObj} imageCrop={imageCrop} fx={fx} />
        )}
      </div>
    )
  }
  console.warn("Image with ID " + image + " not found!")
  return <></>
}

type ImgProps = {
  image: ImageData
  imageCrop?: boolean
  yFactor?: number
  fx?: string
}

function IntersectionWrapper({ image, fx, imageCrop }: ImgProps) {
  const [interSectionref, yFactor] = useIntersection()
  const containerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (containerRef.current) {
      interSectionref(containerRef.current)
    }
  }, [containerRef])
  return (
    <div
      ref={containerRef}
      className="overflow-hidden w-full h-full absolute top-0 left-0"
      style={{
        transform:
          fx === "zoom"
            ? `scale(${yFactor ? 1.4 - yFactor / 2.5 : 1})`
            : "none",
      }}
    >
      <Image image={image} imageCrop={imageCrop} />
    </div>
  )
}

function Image({ image, imageCrop, fx }: ImgProps) {
  const [loaded, setLoaded] = useState(false)
  const imgRef = useRef<HTMLImageElement>(null)

  const handleLoad = () => {
    setLoaded(true)
  }
  useEffect(() => {
    if (imgRef.current?.complete) handleLoad()
  }, [])
  return (
    <>
      <picture className="w-full relative z-0">
        <source srcSet={image.urls.webp.placeHolder} type="image/webp" />
        <source srcSet={image.urls.fallback.placeHolder} />
        <img
          src={image.urls.fallback.placeHolder}
          alt={image.alt}
          className={`w-full h-full p-0 m-0 ${
            imageCrop ? " object-cover " : " object-contain "
          }`}
          style={{
            filter: "blur(2vw)",
            transform: "scale(1.1)",
            objectPosition: `${image.focusX}% ${image.focusY}%`,
          }}
        />
      </picture>

      <picture
        className={`z-10 transition-opacity duration-700 inset-0 absolute left-0 top-0 overflow-hidden  ${
          loaded ? " opacity-100 " : " opacity-0 "
        }`}
        style={{
          clipPath: loaded ? "inset(0px 0px)" : "",
        }}
      >
        <source
          srcSet={image.urls.webp.s}
          media="(max-width:479px)"
          type="image/webp"
        />
        <source srcSet={image.urls.fallback.s} media="(max-width:479px)" />
        <source
          srcSet={image.urls.webp.m}
          media="(min-width: 480px) and (max-width:766px)"
          type="image/webp"
        />
        <source
          srcSet={image.urls.fallback.m}
          media="(min-width: 480px) and (max-width:766px)"
        />
        <source
          srcSet={image.urls.webp.l}
          media="(min-width: 767px) and (max-width:1549px)"
          type="image/webp"
        />
        <source
          srcSet={image.urls.fallback.l}
          media="(min-width: 767px) and (max-width:1549px)"
        />
        <source
          srcSet={image.urls.webp.xl}
          media="(min-width: 1550px) and (max-width:2199px)"
          type="image/webp"
        />
        <source
          srcSet={image.urls.fallback.xl}
          media="(min-width: 1550px) and (max-width:2199px)"
        />
        <source
          srcSet={image.urls.webp.xxl}
          media="(min-width: 2200px)"
          type="image/webp"
        />
        <source srcSet={image.urls.fallback.xxl} media="(min-width: 2200px)" />
        <img
          ref={imgRef}
          onLoad={handleLoad}
          src={image.urls.fallback.l}
          alt={image.alt}
          className={`${
            fx === "fixed"
              ? "w-screen h-screen fixed"
              : "w-full h-full absolute "
          } p-0 m-0 inset-0 ${
            imageCrop ? " object-cover " : " object-contain "
          }`}
          style={{
            objectPosition: `${image.focusX}% ${image.focusY}%`,
          }}
        />
      </picture>
    </>
  )
}
