import React, { ReactNode } from "react"
import useSafeWindowEventlistener from "../../hooks/useSafeWindowEventlistener"
import useStore from "../../store"

type OverlayProps = {}

export default function PopupOverlay({}: OverlayProps) {
  const setPopupMsg = useStore(state => state.setPopupMsg)
  const popupMsg = useStore(state => state.popupMsg)

  useSafeWindowEventlistener("key", () => setPopupMsg(null))
  useSafeWindowEventlistener("click", () => setPopupMsg(null))

  return (
    <div
      onClick={() => setPopupMsg(null)}
      className="w-screen h-screen fixed z-[9999] top-0 left-0 flex justify-center items-center bg-black bg-opacity-40"
    >
      <div className="shadow-md" onClick={e => e.stopPropagation()}>
        {popupMsg}
      </div>
    </div>
  )
}
