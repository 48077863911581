import React, { ReactNode } from "react"

type elementProps = {
  className?: string
  color?: string
  children?: ReactNode
  setInnerHtml?: string
  sizeClass?: string
}

export default function H2({
  className,
  color,
  children,
  setInnerHtml,
  sizeClass,
}: elementProps) {
  return (
    <h2
      style={{ color: color ? color : "inherit" }}
      className={`${
        sizeClass ? sizeClass : " md:text-3xl sm:text-3xl text-3xl"
      } font-titleAccent tracking-widest font-normal m-0 select-none ${className}`}
      //dangerouslySetInnerHTML={setInnerHtml ? { __html: setInnerHtml } : null}
    >
      {children}
    </h2>
  )
}
