import { ImageData } from "@rubendenbrok/bloqmatter-cms"
import checkWebpSupport from "./checkWebpSupport"

export default function getResponsiveImgSrc(image: ImageData) {
  if (typeof window === "undefined") return ""
  const w = window.innerWidth
  if (checkWebpSupport()) {
    if (w < 480) return image.urls.webp.m
    if (w < 766) return image.urls.webp.l
    if (w < 1549) return image.urls.webp.l
    if (w < 2199) return image.urls.webp.xl
    return image.urls.webp.xxl
  } else {
    if (w < 480) return image.urls.fallback.m
    if (w < 766) return image.urls.fallback.l
    if (w < 1549) return image.urls.fallback.l
    if (w < 2199) return image.urls.fallback.xl
    return image.urls.webp.xxl
  }
}
